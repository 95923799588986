.footer {

    background: $primary;
    padding: 2rem 1rem;
    text-align: center;
    color: #B2A619;

    p,
    a {

        color: #B2A619;
        margin-bottom: 0;
        font-size: 0.9rem;
        font-weight: 600;

    }

}