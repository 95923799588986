.header {

    padding: 1.5rem 0;
    position: fixed;
    width: 100%;
    z-index: 9;

    .header-logo {

        max-width: 250px;
        transition: all 0.2s ease-out;

        @include media-breakpoint-down('xs') {

            max-width: 218px;

        }

        @include media-breakpoint-up('xl') {
    
            max-width: 350px;
    
        }
    
    }
    
    &.header-fixed {

        background-color: #fff;
        padding: 1rem 0;

        .header-logo {

            width: 250px;
            transition: all 0.2s ease-in;
        
        }

    }

}

.header-all {

    position: relative;

}

.header-container {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.header-nav.navbar-expand-lg {

    font-family: $headings-font-family;
    text-transform: lowercase;
    padding: 0;
    position: initial;

    .navbar-nav {

        @include media-breakpoint-up('lg') {
            
            max-width: 100%;

        }

        .nav-item:last-child {

            .nav-link {

                padding-right: 0;

            }
            
        }

        .nav-link {

            padding: 0 1rem;
    
        }

    }

}

.navbar-toggler-icon {

    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(32, 32, 152, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");

}

@include media-breakpoint-down('md') {

    .navbar-collapse {

        position: absolute;
        top: 64px;
        width: 100%;
        left: 0px;
        background: #fff;
        padding: 1rem;
        
        .navbar-nav {

            align-items: initial;

        }
    
        .nav-item {
    
            padding: 0.2rem 0;
    
        }
    
    }

}

@include media-breakpoint-down('xs') {

    .navbar-collapse {

        top: 58px;
    
    }

}

