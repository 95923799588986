.productos {

    p {

        text-align: left;

    }

}

.products-accordion {

    margin-top: 3rem;

}

.product-dropdown {

    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $color-grey-02;

}

.product-dropdown_button {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1rem;

    &:hover {

        cursor: pointer;

    }

    &.collapsed {

        .product-dropdown_arrow {

            transform: rotate(45deg);
            transition: all 0.25s ease-out;

        }

    }

    .product-dropdown_title {

        width: calc(25% - 2rem);
        margin-right: 2rem;
        
        .intro-product_text {

            @include media-breakpoint-up('lg') {

                font-size: 1.3rem;

            }

        }

    }

    .product-dropdown_lead {

        width: 75%;

        p {

            max-width: 90%;
            color: $color-secondary;

        }

    }

}

.product-dropdown_arrow {

    border: 1px solid $primary;
    border-width: 0 3px 3px 0;
    position: absolute;
    top: 45%;
    right: 0;
    padding: 5px;
    transform: rotate(225deg);
    transition: all 0.25s ease-in;

}

.product-dropdown_text {

    //display: none;
    padding: 2rem;
    background-color: rgba(193, 193, 193, 0.1);
    
    p {

        color: $color-secondary;

    }

    h4 {

        margin-top: 2rem;

        &:first-child {

            margin-top: 0;

        }

    }

}

.products-market {

    margin-top: 8rem;

}

.market-products_icons {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .product-dropdown_title {
        
        margin: 1rem;
        cursor: pointer;

        .intro-product_icon {

            opacity: 0.7;
            transition: opacity 0.1s ease-out;
    
        }
    
        &:hover {
            
            .intro-product_icon {
    
                opacity: 1;
        
            }
    
        }

    }

    .intro-product_text {

        font-family: $font-family;
        font-weight: 600;
        font-size: 1rem;
        max-width: 100px;

    }

}

// Calidad
.intro-product_icon_full {

    width: 80px;
    height: 80px;
    display: block;
    padding: 0;
    background: none;

}

.calidad-icon {
    
    padding: 2rem 1rem;
    text-align: center;

    .product-dropdown_button {
        
        display: block;
    
    }
    
    .product-dropdown_arrow {
        
        display: block;
        width: 10px;
        margin: 1rem auto 0;
        position: relative;

    }

    .product-dropdown_text {
        background: none;
        padding: 0;
    }

    .intro-product_text,
    p {

        max-width: 250px;
        margin: auto;
        min-height: 55px;

    }

    p {
        
        font-size: 0.9rem;

    }

    @include media-breakpoint-up('lg') {
        
        width: 22%;

    }

}

.calidad {

    .tit-destacado {

        font-family: $font-family;
        font-size: 1.8rem;
        text-transform: uppercase;
        margin-bottom: 0.5rem;

    }

}