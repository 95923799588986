.modal {

    text-align: center;

    &:before {

        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;

    }

    .modal-dialog {

        display: inline-block;
        text-align: left;
        vertical-align: middle;
        width: 90%;

        @media screen and (min-width: 768px) {

            max-width: 650px;

        }

        .modal-body {

            max-height: 65vh;
            overflow-y: auto;

        }

        .modal-footer {

            border-top: none;
            text-align: center;
            justify-content: center;

        }
    }
}

.alert {

    padding-top: 25px;
    padding-bottom: 25px;

    .modal-body {

        font-weight: 300;
        font-size: 1.4em;
        text-align: center;
        padding: 15px 30px;

        img {

            width: 100px;
            height: auto;

        }
    }
}

.checkbox-gdpr {

    input {

        cursor: pointer;
        margin-right: 6px;

    }

    span {

        cursor: pointer;

    }
}

#veissModal {

    h2 {

        font-size: 2rem;

    }

    h3 {

        font-size: 1.3rem;
        margin: 1.7rem 0 1.2rem;

    }

    p,
    li {

        color: $color-secondary;

    }

}
