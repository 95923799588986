.contacto {

    background: url('../images/contacto-fondo.png') no-repeat;
    background-position: -930px 0;
    padding: 5rem 0;

    @include media-breakpoint-up('lg') {

        background-position: right top;

    }

}

.contact-form {

    max-width: 500px;
    width: 100%;
    margin: 2rem auto 0;

    .btn {

        width: 100%;

    }

}

.form-group {

    position: relative;

    label {

        position: absolute;
        color: $primary;
        letter-spacing: 1px;
        top: -15px;
        left: 10px;
        background: #fff;
        border-radius: 4px;
        padding: 0.3rem;
        margin: 0;
        font-weight: 500;
        font-family: $font-family;
        font-size: 0.75rem;
        transition: all 0.1s ease-out;
        opacity: 0;

        &.show {

            opacity: 1;
            transition: all 0.1s ease-in;

        }

    }

    .form-control {

        border: 2px solid $color-secondary;
        border-radius: 4px;
        font-size: 1rem;
        font-family: $font-family;
        padding: 1rem;
        color: $color-secondary;
        height: auto;

        &:focus {

            border: 2px solid $primary;
            color: $color-secondary;
            background-color: #fff;
            border-color: $primary;
            box-shadow: none;

        }

    }

}

.checkbox-gdpr {

    padding-left: 0;

    label {

        color: $color-secondary;
        font-size: 0.8rem;

        @include media-breakpoint-up('lg') {

            font-size: 1rem;

        }

        a {

            color: $primary;
            border-bottom: 1px solid transparent;

            &:hover {

                color: $primary;
                border-bottom: 1px solid $primary;

            }

        }

    }

}

// Direcciones
.direcciones {

    background: url('../images/contacto-direcciones.jpg') no-repeat;
    padding: 4rem 0 1rem;
    background-size: cover;

}

.direcciones-container {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .direccion {

        width: 100%;
        padding: 0 1rem;
        text-align: center;
        margin-bottom: 2rem;

        p {

            line-height: 1.7;

        }

    }

    .direccion-icono {

        margin-bottom: 0.5rem;

    }

}

@include media-breakpoint-up('sm') {

    .direcciones-container {

        .direccion {

            width: 50%;
    
        }

    }

}

@include media-breakpoint-up('md') {

    .direcciones {

        padding: 4rem 0 3rem;
    
    }

    .direcciones-container {

        .direccion {

            width: 33%;
            margin-bottom: 0;
    
        }

    }

}