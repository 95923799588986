body {

    font-family: $font-family;
    font-weight: $font-weight;

}

h1,
h2,
h4 {

    font-family: $headings-font-family;
    color: $primary;

}

h2 {

    font-size: 2rem;
    margin-bottom: 1.5rem;
    margin-top: 2rem;

    @include media-breakpoint-up('lg') {

        font-size: $font-size-headings;

    }

}

h3 {

    font-family: $font-family;
    color: $primary;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 6px;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up('lg') {

        font-size: 1.5rem;

    }

}

h4 {

    font-size: 1.3rem;
    margin: 1.5rem 0 1rem;

}

p,
li {

    font-size: $font-size-base;
    color: $primary;
    font-weight: 400;

}

p.destacado {

    font-size: 1.2rem;
    color: $color-grey-06;
    font-weight: 300;

}

strong {

    font-weight: 600;
    color: $primary;

}

a {

    transition: all 0.1s ease-out;

    &:hover {

        color: $primary;
        transition: all 0.1s ease-in;
        text-decoration: none;
        cursor: pointer;

    }

}

li {
    margin-bottom: 10px;
}

section {

    h1,
    h2,
    h3,
    p {

        text-align: center;

    }

    .link-section {

        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translate(-50%, -1%);

        .arrow-down {

            position: relative;
            bottom: 0;
            transition: bottom 0.2s ease-in;

        }

        &:hover {

            .arrow-down {

                bottom: -1.5rem;
                transition: bottom 0.2s ease-out;

            }

        }

    }

    .section-texto {

        max-width: 1000px;
        margin: auto;

    }

}

.py-section {
    padding: 2rem 0 4rem;
}

.link-wave {

    display: block;

    &:after {
    
        content: '';
        display: block;
        background: url('../images/wave.svg') repeat-x;
        background-size: contain;
        width: 100%;
        height: 4px;
        margin-top: 0.125rem;
        opacity: 0;
        transition: all 0.1s ease-in;

    }

    &:hover {


        &:after {

            opacity: 1;
            transition: all 0.1s ease-out;

        }

    }

}

.btn-primary {

    border-radius: 4px;
    box-shadow: 0 1px 3px 2px rgba(0,0,0,0.2);
    text-transform: uppercase;
    font-size: 0.95rem;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 0.6rem 1.5rem;
    margin: 0.5rem auto;
    display: inline-block;

}

.separator {
    width: 100%;
    height: 1px;
    background-color: $color-grey-02;
    margin: 3rem 0;
}

.single-page {

    .section {

        padding: 10rem 0 5rem;

    }
    
}

.text-left p {
    text-align: left;
}

.text-secondary,
.text-secondary p {
    color: $color-secondary;
}

// Cookies

.footer .cookie-advise {
    box-shadow: 0 0 6px rgba(0,0,0,0.4);
    font-family: $font-family;
    position: fixed;
    z-index: 1040;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    max-width: 100%;
    width: 90%;
    font-size: 0.9em;
    background-color: rgba(255,255,255,0.8);
    border: 1px dotted #000;
    padding-top: 20px;
    padding-bottom: 20px;

    p {
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: $primary;
        margin-bottom: 0.3125rem;
    }

    .cookie-advise-text {
        padding-bottom: 1rem;
        @include media-breakpoint-up('xxl') {
            padding-bottom: 0;
        }
        a {
            color: #000;
            font-weight: 600;
            border-bottom: 1px dotted #000;
            &:hover {
                color: $primary;
                border-bottom: 1px dotted $primary;
            }
        }
    }

    .cookie-advise-bt {
        color: #fff;
    }

    .text-center a {
        margin-bottom: 0.5rem;
    }
}

.cookie-advise-close {
    position: absolute!important;
    top: 8px;
    right: 8px;
    text-indent: -9999px;
}

// Legal

.legal {

    padding: 10rem 0 5rem;

    p {

        text-align: left;
        color: $color-secondary;

    }

    h1 {
        text-align: center;
        font-size: 3rem;
        margin-bottom: 2rem;
    }

    h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    h3 {
        margin-top: 2rem;
    }

    a {
        border-bottom: 1px solid transparent;

        &:hover {
            border-bottom: 1px solid $primary;
        }
    }

}

table {
    margin: 1.5rem 0;
    
    tbody {
        td,
        th {
            font-size: 0.95rem;

            &:first-child {
                min-width: 145px;
            }
        }

        th {
            background-color: rgba(193,193,193,0.1);
        }
    }
}

.toggle-cookie {
    background-color: #db1818;
    border: none;
    padding: 0.3rem 0.7rem;
    color: #fff;
    border-radius: 4px;

    &:hover {
        background-color: #c11515;
    }

    &.toggled {
        background-color: $primary;

        &:hover {
            background-color: #151573;
        }
    }
}