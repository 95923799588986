.clientes-logos {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem; 

    img {

        max-height: 33px;
        margin: 1.2rem 1rem;

    }

}

.partners {
    text-align: center;

    .btn {
      margin-bottom: 5rem;
    }
}