.inicio-hero {

    .slick-dots {
        bottom: 60px;

        li {

            button:before {

                width: 15px;
                height: 15px;
                font-size: 0;
                background-color: $primary;
                border-radius: 50%;

            }

        }

    }

}

.inicio-hero_slide {

    width: 100%;
    min-height: 600px;
    position: relative;
    display: flex!important;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        object-fit: cover;
        object-position: center top;
        position: absolute;
        z-index: -1;
        top: 0;
        min-height: 95vh;
    }

    h1 {

        font-size: 2rem;
        color: $primary;
        line-height: 0.9;

    }

    h2 {

        font-family: $font-family;
        font-size: 1.4rem;
        margin: 1rem;

    }

    .inicio-hero_text {

        max-width: 95%;
        text-align: center;
        width: 100%;
        margin-top: 3rem;

    }

    .link-section {

        bottom: -4%;

    }

    @include media-breakpoint-up('sm') {

        min-height: 95vh;

        .inicio-hero_text {
            
            max-width: 600px;

        }

        h1 {

            font-size: 3rem;

        }

    }

    @include media-breakpoint-up('xlg') {

        .inicio-hero_text {
            
            max-width: 50%;

        }

        h1 {

            font-size: 4rem;

        }

        h2 {
            
            font-family: $font-family;
            font-size: 2rem;

        }

    }

}

.intro-products {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3rem;

}

.intro-product_text {

    display: block;
    color: $primary;
    font-family: $headings-font-family;
    font-size: 1.2rem;
    padding-top: 0.5rem;
    line-height: 1.2;
    text-align: center;

    @include media-breakpoint-up('lg') {

        font-size: 1.5rem;
        padding-top: 1rem;

    }

}

.intro-product {
    
    margin-bottom: 1rem;
    padding: 1rem 2.5rem;

}

.intro-product_icon {

    width: 5rem;
    height: 5rem;
    background-color: $primary;
    border-radius: 100%;
    padding: 1.3rem;
    display: block;
    margin: 0 auto;

    img {
        display: block;
    }

}

.intro-peces {

    padding-bottom: 2.5rem;
    position: relative;

    .intro-peces_img {

        width: 100%;
        object-fit: cover;
        min-height: 400px;

    }

}