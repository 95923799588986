.historia {
    p {
        text-align: left;
        color: $color-secondary;
    }
}

.timeline {
    position: relative;
    margin: 4rem auto;
  }
  
  .timeline::after {
    content: '';
    position: absolute;
    width: 4px;
    background-color: $primary;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -8px;
  }
  
  .timeline-container {
    position: relative;
    width: 50%;
  }
  
  .timeline-container::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    right: -4px;
    background-color: #fff;
    border: 3px solid $primary;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  
  .left {
    left: 0;

    .content {
        margin-left: auto;
        margin-right: 5px;
        right: 35px;
    }
  }
  
  .right {
    left: 50%;

    .content {
        margin-right: auto;
        margin-left: 5px;
        left: 35px;
    }
  }
  
  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 15px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #ddd;
  }
  
  .right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 15px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent #ddd transparent transparent;
  }
  
  .right::after {
    left: -16px;
  }
  
  .content {
    position: relative;
    border-radius: 6px;
    padding: 1rem;
    border: 1px solid #ddd;
    max-width: 350px;
    margin: auto;
  }
  
  @include media-breakpoint-down('sm') {

    .timeline {
        left: 55%;
        transform: translate(-50%, 0);
    }

    .timeline::after {
      left: 31px;
    }
  
    .timeline-container {
      width: 100%;
      padding-left: 3.375rem;
      padding-right: 1.5625rem;
      margin-bottom: 1.5rem;
    }
  
    .timeline-container::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
    }
  
    .left::after, .right::after {
      left: 15px;
    }
  
    .right {
      left: 0%;
    }

    .left .content,
    .right .content {
        margin-left: 0;
        margin-right: auto;
        left: initial;
        right: initial;
    }
  }

  p.timeline-year {
    color: $primary;
    font-family: $headings-font-family;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  
  p.timeline-text {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 0;
  }