/*Variables*/

$primary: #202098;
$color-secondary: #656565;
$headings-color: #000;
$hover-color: #000;
$gray-light: #dfdfdf;
$light-text: #FFF;
$lschool-verde: #4b787d;

$color-grey-01: #f6f6f6;
$color-grey-02: #dddddd;
$color-grey-03: #d7d7d7;
$color-grey-04: #c4c4c4;
$color-grey-05: #999999;
$color-grey-06: #656565;
$color-grey-07: #3d3d3d;

$text-color: $color-grey-07;
$link-color: $primary;
$link-hover-color: #B11730;
$button-color: $primary;
$p-margin-bottom: 15px;
$li-margin-bottom: 10px;
//$text-line-height: 1.4em;
$strong-font-weight: 700;
$font-family: 'Raleway', Arial, sans-serif;
$headings-font-family: 'DM Serif Display', Arial, sans-serif;
$font-size-base: 1.1rem;
$font-size-headings: 3rem;
$golden-ratio: 1.6180339888;

$font-weight: 400;
$font-weight-lg: 400;

$line-height-text: 1.625;
$line-height-headings: 1.2em;

$border-radius:               0;
$border-radius-lg:            0;
$border-radius-sm:            0;

$btn-box-shadow:              none;
$btn-focus-box-shadow:        none;
$btn-active-box-shadow:       none;
$btn-border-width:            2px;

/*Personalización del Grid de Bootstrap*/
$grid-columns:      12;
$grid-gutter-width: 30px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px,
  // Extra Extra large screen / wide desktop
  xlg: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  xlg: 1640px
);